import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

//Modules Catalogos
import centroTrabajoStoreModule from '@/views/catalogos/centro-trabajo/centroTrabajoStoreModule';
import empleadoStoreModule from '@/views/catalogos/empleados/empleadoStoreModule';
import mallaStoreModule from '@/views/catalogos/mallas/mallaStoreModule';
import gastosStoreModule from '@/views/catalogos/gastos/gastosStoreModule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    'app-centro-trabajo' : centroTrabajoStoreModule,
    'app-empleado' : empleadoStoreModule,
    'app-malla' : mallaStoreModule,
    'app-cat-gastos' : gastosStoreModule
  },

  strict: process.env.DEV,
});
