export default [
  {
    path: '/movimientos/captura-gastos',
    name: 'mov-captura-gastos',
    component: () => import('@/views/movimientos/gastos/movGastosList.vue'),
    meta: {
      pageTitle: 'Listar',
      breadcrumb: [
        {
          text: 'Movimientos',
        },
        {
          text: 'Listado de Gastos',
        },
      ],
    },
  },
    {
      path: '/movimientos/captura-movimientos',
      name: 'mov-captura-movimientos',
      component: () => import('@/views/movimientos/ajuste-fecha-antiguedad/movAjusteFechaAntiguedadList.vue'),
      meta: {
        resource: 'Checador',
        pageTitle: 'Listar',
        breadcrumb: [
          {
            text: 'Movimientos',
          },
          {
            text: 'Listado de Movimientos',
          },
        ],
      },
    },
    {
      path: '/movimientos/captura-movimientos/agregar',
      name: 'mov-captura-movimientos-agregar',
      component: () => import('@/views/movimientos/ajuste-fecha-antiguedad/movAjusteFechaAntiguedadAdd.vue'),
      meta: {
        resource: 'Movimientos',
        pageTitle: 'Registrar',
        breadcrumb: [
          {
            text: 'Movimientos',
          },
          {
            text: 'Captura de Movimientos',
          },
        ],
      },
    },
    {
      path: '/movimientos/captura-movimientos/editar/:id',
      name: 'mov-captura-movimientos-editar',
      component: () => import('@/views/movimientos/ajuste-fecha-antiguedad/movAjusteFechaAntiguedadEdit.vue'),
      meta: {
        resource: 'Movimientos',
        pageTitle: 'Editar',
        breadcrumb: [
          {
            text: 'Movimientos',
          },
          {
           text: 'Ajuste de Fecha de Antigueda',
          }
        ],
      },
    },
    {
      path: '/movimientos/reporte-movimientos',
      name: 'mov-reportes-movimientos',
      component: () => import('@/views/movimientos/reportes/movReporteSemanal.vue'),
      meta: {
        resource: 'Reportes',
        pageTitle: 'Reportes',
        breadcrumb: [
          {
            text: 'Movimientos',
          },
          {
            text: 'Reporte Semanal',
          },
        ],
      },
    },
    {
      path: '/movimientos/reporte-mallas',
      name: 'mov-reportes-mallas',
      component: () => import('@/views/movimientos/reportes/movReporteMallas.vue'),
      meta: {
        resource: 'Reportes',
        action: 'read',
        pageTitle: 'Reportes',
        breadcrumb: [
          {
            text: 'Movimientos',
          },
          {
            text: 'Reporte Totales de Mallas',
          },
        ],
      },
    },
    
];