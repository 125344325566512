export default [
	{
		path: '/administracion/usuarios',
		name: 'administracion-usuarios',
		component: () => import('@/views/administracion/usuarios/usuarios-list/UsersList.vue'),
		meta: {
			pageTitle: 'Listar',
			breadcrumb: [
				{
					text: 'Administracion',
				},
				{
					text: 'Usuarios',
				},
			],
		},
	},
	{
		path: '/administracion/permisos',
		name: 'administracion-permisos',
		component: () => import('@/views/administracion/permisos/permisosList.vue'),
		meta: {
			pageTitle: 'Listar',
			breadcrumb: [
				{
					text: 'Administracion',
				},
				{
					text: 'Permisos',
				},
			],
		},
	},
	{
		path: '/administracion/roles',
		name: 'administracion-roles',
		component: () => import('@/views/administracion/roles/catRolesList.vue'),
		meta: {
			pageTitle: 'Listar',
			breadcrumb: [
				{
					text: 'Administracion',
				},
				{
					text: 'Roles',
				},
			],
		},
	},
	{
		path: '/administracion/roles/agregar',
		name: 'administracion-roles-agregar',
		component: () =>
		  import('@/views/administracion/roles/catRolesAdd.vue'),
		meta: {
		  pageTitle: 'Registrar',
		  breadcrumb: [
			 {
				text: 'Administracion',
			 },
			 {
				text: 'Roles',
			 },
		  ],
		},
	 },
	 {
		path: '/administracion/roles/editar/:id',
		name: 'administracion-roles-editar',
		component: () =>
		  import(
			 '@/views/administracion/roles/catRolesEdit.vue'
		  ),
		meta: {
		  pageTitle: 'Editar',
		  breadcrumb: [
			 {
				text: 'Administracion',
			 },
			 {
				text: 'Roles',
			 },
		  ],
		},
	 },


];
