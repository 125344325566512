export default [
    {
      path: "/checador/registro",
      name: "checador-registro",
      component: () =>
        import(
          "@/views/checador/registro/ChecadorRegistro.vue"
        ),
      meta: {
        resource: 'Checador',
        pageTitle: "Registro de asistencia",
        breadcrumb: [
          {
            text: 'Checador',
          },
          {
            text: "Registro de asistencia",          
          },
        ],
      },
    }, 
    {
      path: "/checador/general",
      name: "checador-general",
      component: () =>
        import(
          "@/views/checador/registro/ChecadorGeneral.vue"
        ),
      meta: {
        resource: 'Checador',
        pageTitle: "Registro de asistencia General",
        breadcrumb: [
          {
            text: 'Checador',
          },
          {
            text: "Registro de asistencia",          
          },
        ],
      },
    }, 
  ];
  