export default [
  {
    path: '/dashboard/inicio',
    name: 'dashboard-inicio',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta: {
      resource: 'Analitycs',
      action: 'read',
    }
  }
]
